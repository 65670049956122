/* Main Container */
.josaa-predictor-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Titles */
.predictor-title {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 25px;
  font-size: 1.8rem;
}

/* User Summary Card */
.user-summary-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-summary-card h3 {
  margin-top: 0;
  color: #3498db;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.user-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.detail-item {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.detail-label {
  font-weight: 600;
  color: #7f8c8d;
  display: block;
  margin-bottom: 5px;
}

.detail-value {
  font-size: 1.1rem;
  color: #2c3e50;
}

/* Results Section */
.results-section {
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.results-section h3 {
  margin-top: 0;
  color: #2c3e50;
  font-size: 1.5rem;
}

.results-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
}

.results-table th {
  background-color: #3498db;
  color: white;
  padding: 12px;
  text-align: left;
}

.results-table td {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.results-table tr:hover {
  background-color: #f1f1f1;
}

/* Loading and Error States */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.error-container {
  text-align: center;
  padding: 20px;
}

.error-container .ant-alert {
  margin-bottom: 15px;
}

.retry-button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.retry-button:hover {
  background-color: #2980b9;
}

.no-results-message {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
  font-style: italic;
  background-color: #f8f9fa;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-details-grid {
    grid-template-columns: 1fr;
  }
  
  .results-table th, 
  .results-table td {
    padding: 8px;
    font-size: 0.9rem;
  }
}